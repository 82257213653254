import { useNavigate } from '@segunosoftware/equinox';
import ReviewBanner from '../components/ReviewBanner';
import { useAccount, useReviewSentiment, useReviewStatus } from '../hooks/useAccount';

export default function ReviewBannerContainer(props) {
	const account = useAccount();
	const onSetReviewSentiment = useReviewSentiment();
	const onSetReviewStatus = useReviewStatus();
	const navigate = useNavigate();

	return (
		<ReviewBanner
			account={account}
			onSetReviewSentiment={onSetReviewSentiment}
			onSetReviewStatus={onSetReviewStatus}
			navigate={navigate}
			{...props}
		/>
	);
}
