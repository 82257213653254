import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { CACHED_ACCOUNT, LOAD_SYNC_STATUS, USER } from './query-keys';
import type { Get, Post, Put } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export const useReviewSentiment = () => {
	const { post } = useAuthenticatedFetch() as Post<any, void>;
	const { mutate } = useMutation({
		mutationFn: (reviewSentiment: any) =>
			post('/account/review-sentiment', {
				reviewSentiment
			})
	});
	return mutate;
};

export const useReviewStatus = () => {
	const { post } = useAuthenticatedFetch() as Post<any, void>;
	const { mutate } = useMutation({
		mutationFn: (reviewStatus: any) =>
			post('/account/review-status', {
				reviewStatus
			})
	});
	return mutate;
};

export function useAccountFetch(load = false) {
	const client = useQueryClient();
	const { get } = useAuthenticatedFetch() as Get<any>;
	const {
		data: account,
		isFetching: isLoading,
		refetch: onLoadAccount
	} = useQuery({
		queryKey: queryKey(USER),
		queryFn: () => get('/auth/whoami'),
		select: useDateTransformerFactory(),
		enabled: load
	});
	useEffect(() => {
		const cachedAccount = client.getQueryData(queryKey(CACHED_ACCOUNT));
		if (cachedAccount === null && !isLoading && account) {
			client.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
		}
	}, [account, isLoading, client]);
	return { account, isAccountLoading: isLoading, onLoadAccount };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useCachedAccount() {
	const client = useQueryClient();
	const { data: account } = useQuery({
		queryKey: queryKey(CACHED_ACCOUNT),
		queryFn: () => Promise.resolve(client.getQueryData(queryKey(USER)) || null)
	});
	return account;
}

export function useSaveSettings() {
	const { put } = useAuthenticatedFetch() as Put<any, any>;
	const { mutate: onSave, isPending: isSaving } = useMutation({
		mutationFn: (payload: any) => put('/account/settings', payload)
	});
	return { onSave, isSaving };
}

export function useCompleteMailChimpConnection() {
	const { post } = useAuthenticatedFetch() as Post<any, any>;
	const { mutate: onFinish, isPending: isFinishing } = useMutation({
		mutationFn: (payload: any) => post('/account/complete-mailchimp-connection', payload)
	});
	return { onFinish, isFinishing };
}

export function useLoadSyncStatus(load = false) {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const { isFetching: isLoadingStats, refetch: onLoadStats } = useQuery({
		queryKey: queryKey(LOAD_SYNC_STATUS),
		queryFn: () => get('/account/sync-status'),
		select: useDateTransformerFactory(),
		enabled: load
	});
	return { isLoadingStats, onLoadStats };
}

export function useStartSync() {
	const { post } = useAuthenticatedFetch() as Post<any, any>;
	const { mutate: onStartSync, isPending: isStartSyncLoading } = useMutation({
		mutationFn: (payload: any) => post('/account/start-sync', payload)
	});
	return { onStartSync, isStartSyncLoading };
}
