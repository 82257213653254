import { useToast } from '@segunosoftware/equinox';
import Settings from '../../components/settings/Settings';
import { useAccount, useSaveSettings } from '../../hooks/useAccount';
import { useMailChimpGetMergeFields } from '../../hooks/useMailChimp';

export default function SettingsContainer(props) {
	const account = useAccount();
	const { onSave, isSaving } = useSaveSettings();
	const { mergeFields, isMergeFieldsLoading, onLoadMergeFields } = useMailChimpGetMergeFields();
	const showToast = useToast();
	return (
		<Settings
			account={account}
			onSave={onSave}
			isSaving={isSaving}
			isMergeFieldsLoading={isMergeFieldsLoading}
			mergeFields={mergeFields}
			onLoadMergeFields={onLoadMergeFields}
			showToast={showToast}
			{...props}
		/>
	);
}
