import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import OAuth from './components/oauth/OAuth';
import AppEmbedContainer from './containers/AppEmbedContainer';
import ProtectedRoute from './containers/ProtectedRoute';
import SentryWrapperContainer from './containers/SentryWrapperContainer';
import MailChimpConnectContainer from './containers/oauth/MailChimpConnectContainer';

export default function App() {
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapperContainer>
				<Routes>
					<Route path="/oauth/mailchimp/connect" element={<ProtectedRoute element={<MailChimpConnectContainer />} />} />
					<Route path="/oauth/*" element={<OAuth />} />
					<Route path="/*" element={<ProtectedRoute element={<AppEmbedContainer />} />} />
				</Routes>
			</SentryWrapperContainer>
		</AppProvider>
	);
}
