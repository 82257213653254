import { LoadingScreen } from '@segunosoftware/equinox';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';

export default function ProtectedRoute({ element }) {
	const [appBridgeLoaded, setAppBridgeLoaded] = useState(false);
	const appBridgeScriptInjected = useRef(false);

	useEffect(() => {
		if (!appBridgeScriptInjected.current) {
			appBridgeScriptInjected.current = true;
			const appBridgeScript = document.createElement('script');
			appBridgeScript.src = 'https://cdn.shopify.com/shopifycloud/app-bridge.js';
			appBridgeScript.async = false;
			appBridgeScript.onload = () => setAppBridgeLoaded(true);
			document.head.prepend(appBridgeScript);
		}
	}, []);

	if (appBridgeLoaded) {
		return <ProtectedRouteWithAppBridge element={element} />;
	}

	return <LoadingScreen />;
}

function ProtectedRouteWithAppBridge({ element }) {
	const location = useLocation();
	const { account } = useAccountFetch(true);

	if (!account) {
		return <LoadingScreen />;
	}

	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	const isFrozenPath = location.pathname.indexOf('/frozen') === 0;
	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (account.billingStatus === 'frozen' && !isFrozenPath) {
		return <Navigate to="/frozen" />;
	}
	if ((!account.suspended && isSuspendedPath) || (account.billingStatus !== 'frozen' && isFrozenPath)) {
		return <Navigate to="/" />;
	}

	return element;
}
