import { LoadingScreen } from '@segunosoftware/equinox';
import { Banner, Layout, Page } from '@shopify/polaris';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';

export default class MailChimpCallback extends Component {
	static propTypes = {
		onConnected: PropTypes.func.isRequired,
		error: PropTypes.string,
		isConnectedLoading: PropTypes.bool,
		navigate: PropTypes.func.isRequired
	};

	static defaultProps = {
		isConnectedLoading: false,
		error: null
	};

	componentDidMount() {
		const { onConnected } = this.props;
		const { state, code } = queryString.parse(window.location.search);
		onConnected(state, code);
	}

	componentDidUpdate(prevProps, prevState) {
		const { isConnectedLoading, error, navigate } = this.props;
		if (!isConnectedLoading && prevProps.isConnectedLoading && !error) {
			navigate({
				to: '/settings/mailchimp-finish'
			});
		}
	}

	render() {
		const { error } = this.props;
		if (error) {
			return (
				<Page>
					<Layout sectioned>
						<Banner title="Error connecting to Mailchimp" tone="critical">
							<p>{error} Please contact support for additional help.</p>
						</Banner>
					</Layout>
				</Page>
			);
		}
		return <LoadingScreen />;
	}
}
