import { useNavigate } from '@segunosoftware/equinox';
import { useState } from 'react';
import MailChimpCallback from '../../components/oauth/MailChimpCallback';
import { useMailChimpConnected } from '../../hooks/useMailChimp';

export default function MailChimpCallbackContainer(props) {
	const [queryConfig, setQueryConfig] = useState({
		shop: null,
		code: null
	});

	const { isConnectedLoading, error } = useMailChimpConnected(...queryConfig);
	const navigate = useNavigate();

	return (
		<MailChimpCallback
			isConnectedLoading={isConnectedLoading}
			error={error}
			onConnected={(shop, code) => setQueryConfig({ shop, code })}
			navigate={navigate}
			{...props}
		/>
	);
}
